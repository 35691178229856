import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  FadeReveal,
  Image,
  FilteredList,
  MultipleComponentIterator,
  InternalLink,
  MultipleComponentIteratorMap,
  FullImageCard,
  FishermanIcon,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
  withArgs,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createServiceItemSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessFile,
      allFishermanBusinessMenuItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"services-page"}>
          <div className={"content-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <Header
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_header",
                    defaultValue: "Services",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <FadeReveal triggerOnce={true}>
                  <Image
                    className={"image"}
                    background={true}
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "Content.Image",
                      componentIdentifier: "content_section_image",
                      numToSelect: 1,
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
          <FilteredList
            items={allFishermanBusinessMenuCategory.nodes}
            itemFilterKey={"schedule.name"}
            childItemsKey={"iterator"}
            defaultFilterValue={"All Services"}
            displayAllFilterValue={"All Services"}
            filterValues={allFishermanBusinessMenuSchedule.nodes}
            filterValueKey={"name"}
            filterValuesComponent={<Button basic={true} />}
            className={"services-filtered-list"}
            parentContainer={<div className={"services-section"} />}
          >
            <MultipleComponentIterator
              components={[
                {
                  component: <div className={"service-container"} />,
                  propMap: { key: "_id" },
                  children: [
                    {
                      component: (
                        <Grid
                          className={"category-info"}
                          stackable={true}
                          doubling={true}
                          columns={2}
                        />
                      ),
                      children: [
                        {
                          component: (
                            <Grid.Column className={"info-column"} width={8} />
                          ),
                          children: [
                            {
                              component: (
                                <Header className={"name"} as={"h2"} />
                              ),
                              propMap: { content: "name" },
                            },
                            {
                              component: (
                                <MarkdownContent className={"description"} />
                              ),
                              propMap: { content: "description" },
                            },
                            {
                              component: (
                                <Button
                                  basic={true}
                                  content={"View Details"}
                                  event={{
                                    category: "Services",
                                    action: "View Category Details",
                                  }}
                                  as={InternalLink}
                                />
                              ),
                              propMap: {
                                "event.label": "name",
                                to: createServiceCategorySlug,
                              },
                            },
                          ],
                        },
                        {
                          component: (
                            <Grid.Column className={"image-column"} width={8} />
                          ),
                          children: [
                            {
                              component: <Image className={"image"} />,
                              propMap: {
                                src: withNamedArgs({
                                  func: getBusinessFiles,
                                  args: {
                                    businessFiles:
                                      allFishermanBusinessFile.nodes,
                                    numToSelect: 1,
                                  },
                                }),
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      component: (
                        <Card.Group
                          className={"category-items"}
                          stackable={true}
                          itemsPerRow={2}
                        />
                      ),
                      children: [
                        {
                          component: (
                            <MultipleComponentIteratorMap
                              lookupField={"_id"}
                              limit={4}
                              iterator={allFishermanBusinessMenuItem.nodes}
                              components={[
                                {
                                  component: (
                                    <FadeReveal
                                      className={"ui card"}
                                      triggerOnce={true}
                                    />
                                  ),
                                  propMap: { key: "_id" },
                                  children: [
                                    {
                                      component: (
                                        <FullImageCard
                                          as={InternalLink}
                                          link={true}
                                          event={{
                                            category: "Services",
                                            action: "View Item Details",
                                          }}
                                        />
                                      ),
                                      propMap: {
                                        image: withNamedArgs({
                                          func: getBusinessFiles,
                                          args: {
                                            businessFiles:
                                              allFishermanBusinessFile.nodes,
                                            numToSelect: 1,
                                          },
                                        }),
                                        "event.label": "name",
                                        to: withArgs({
                                          func: createServiceItemSlug,
                                          args: [
                                            allFishermanBusinessMenuCategory.nodes,
                                          ],
                                        }),
                                      },
                                      children: [
                                        {
                                          component: <Card.Header as={"h3"} />,
                                          propMap: { content: "name" },
                                        },
                                        {
                                          component: (
                                            <FishermanIcon
                                              iconName={"arrow-right"}
                                            />
                                          ),
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ]}
                            />
                          ),
                          propMap: { targetValues: "items" },
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
          </FilteredList>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuCategory(sort: { fields: [order], order: [ASC] }) {
      nodes {
        description
        name
        _id
        items
        scheduleId
        schedule {
          _id
          name
        }
        files
      }
    }
    allFishermanBusinessMenuSchedule(sort: { order: ASC, fields: order }) {
      nodes {
        _id
        name
        order
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
      }
    }
  }
`;
